<style>
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
  font-size: 50px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Microsoft Yahei", sans-serif;
  overflow: hidden;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
  overflow: hidden;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}
</style>

<template>
  <div class="flex-col page">
    <div class="flex-col justify-start flex-auto group_2">
      <div class="flex-col justify-start relative section">
        <div class="flex-col section_2 space-y-8 pos_2">
          <div class="flex-col group_3">
            <div class="flex-row items-start">
              <img
                class="shrink-0 image_3"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798802081179792444.png"
              />
              <div class="flex-col items-center flex-auto group_4">
                <span class="text_2">为您精选以下机构和产品</span>
                <span class="text_3">专业贷款咨询服务，在线秒接单</span>
              </div>
              <div
                class="flex-col justify-start items-center shrink-0 image-wrapper"
              ></div>
            </div>
            <div class="flex-col relative section_3">
              <div class="flex-row justify-between items-center group_5">
                <span class="font_1">产品指定方案</span>
                <div class="flex-col">
                  <div class="flex-row items-start space-x-6">
                    <span class="font_1">已选1个产品</span>
                  </div>
                  <span class="self-start font_2 text_4"
                    >（共可选1个产品）</span
                  >
                </div>
              </div>
              <div class="flex-row group_6">
                <img
                  class="image_6"
                  :src="
                    model === 'model9'
                      ? require('../../../public/aed_logo.png')
                      : require('../../assets/images/model8/modelLogo.png')
                  "
                />
                <span class="font_3 text_5">{{
                  this.apiProduct.productName
                }}</span>
                <span class="font_2 text_company">{{
                  this.apiProduct.companyName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_4 space-y-24 pos_4">
        <div class="group_7">
          <span class="font_3 text_6">特别提醒：</span>
          <span class="font_3 text_7"
            >{{
              this.apiProduct.name
            }}会根据您的综合资信状况，为您推荐以下贷款机构产品:</span
          >
          <span class="font_3 text_8">{{ this.apiProduct.companyName }}</span>
          <span class="font_3 text_9">，具体请阅读并同意相关协议</span>
          <span class="font_3 text_10" v-on:click="handleProtocol(12)"
            >《个人信用查询授权书》</span
          >
        </div>
        <div class="flex-col space-y-8">
          <div class="flex-col justify-start items-start relative group_8">
            <span class="font_4 text_11" v-html="apiProduct.content"> </span>
          </div>
        </div>
      </div>
      <div class="flex-col justify-start section_8 pos_7">
        <div
          class="flex-col justify-start items-center button"
          v-on:click="handleAgreeProtocol"
        >
          <span class="text_13">同意协议，激活额度</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "apiResults",
  data() {
    return {
      channel: this.$route.query.source,
      model: sessionStorage.getItem("model"),
      rpChecked: false,
      orderId: this.$route.query.orderId,
      apiProduct: {
        recordId: undefined,
        productName: "",
        productId: "",
        companyName: "",
        imgUrl: "",
        thirdPartyId: undefined,
        agencyCode: undefined,
        orderId: "",
        isIncoming: undefined,
        content: "",
      },
    };
  },
  mounted() {
    window.sessionStorage.removeItem("showPopUpView");
    window.sessionStorage.setItem("model", "model9");
    this.getAPIProduct();
  },
  methods: {
    //API机构和授权书
    getAPIProduct() {
      var that = this;
      let data = {
        orderId: that.orderId,
      };
      API_Common.getApiResultsInfo(data).then((res) => {
        if (res.code == 0) {
          that.apiProduct = res.data;
        }
      });
    },
    //同意协议
    handleAgreeProtocol() {
      if (this.apiProduct.isIncoming == 0) {
        var that = this;
        let data = {
          orderId: that.orderId,
          orgId: that.orgId,
        };
        API_Common.callPushData(data).then((res) => {
          if (res.code == 200) {
            this.$router.push({
              name: "APIApplyResults",
              query: {
                source: this.$route.query.source,
              },
            });
          } else {
            this.$router.push({
              name: "error",
              query: {
                source: this.$route.query.source,
              },
            });
          }
        });
      } else {
        this.$router.push({
          name: "error",
          query: {
            source: this.$route.query.source,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
@import "../../css/model8PopView.css";
</style>
<style scoped lang="css">
body {
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
  height: 100vh;
}

.page {
  width: 100%;
  overflow-y: hidden;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
}
.header {
  padding-bottom: 0.24rem;
  background-color: #ffffff;
  height: 1.77rem;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 0.24rem;
}
.image {
  width: 7.5rem;
  height: 0.88rem;
}
.group {
  padding: 0.08rem 0.32rem 0;
}
.image_2 {
  width: 0.4rem;
  height: 0.4rem;
}
.pos {
  position: absolute;
  left: 0.32rem;
  top: 50%;
  transform: translateY(-50%);
}
.font_1 {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium;
  color: #000000;
}
.text {
  font-size: 0.34rem;
  line-height: 0.31rem;
}
.group_2 {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: url("../../assets/images/model8/bg.png");
  border-radius: 0.02rem;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}
.section {
  padding-top: 7.48rem;
  border-radius: 0.02rem;
}
.section_6 {
  background-image: linear-gradient(180deg, #f9f9f900 0%, #f5f8ff 100%);
  border-radius: 0.02rem;
  height: 5.4rem;
}
.section_2 {
  padding-top: 0.3rem;

  border-radius: 0.02rem;
}
.space-y-88 > *:not(:first-child) {
  margin-top: 1.77rem;
}
.pos_2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.group_3 {
  padding-left: 0.24rem;
  height: 3.66rem;
}
.image_3 {
  width: 1.08rem;
  height: 1.14rem;
}
.group_4 {
  margin-left: 0.08rem;
  margin-top: 0.24rem;
}
.space-y-10 > *:not(:first-child) {
  margin-top: -0.08rem;
}
.text_2 {
  color: #ffffff;
  font-size: 0.32rem;
  font-family: SourceHanSansSC;
  margin-top: -0.05rem;
}
.text_3 {
  color: #ffffff;
  font-size: 0.24rem;
  font-family: SourceHanSansSC;
  margin-top: -0.01rem;
}
.image-wrapper {
  margin: 0.16rem -1.2rem 0 0.32rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 3.44rem;
  height: 3.2rem;
}
.image_4 {
  filter: drop-shadow(0.28rem 0.18rem 0.09rem #1c7bf5);
  width: 3.44rem;
  height: 3.2rem;
}
.section_3 {
  margin: -2.2rem 0.3rem 0 0.06rem;
  padding: 0.32rem 0.16rem 0;
  background-color: #ffffff;
  border-radius: 0.16rem;
}
.group_5 {
  padding: 0rem 0.12rem 0rem 0.2rem;
  border-bottom: solid 1px #eeeeee;
}
.space-x-6 > *:not(:first-child) {
  margin-left: 0.12rem;
}
.image_5 {
  margin-top: 0.12rem;
  width: 0.26rem;
  height: 0.26rem;
}
.font_2 {
  font-size: 0.22rem;
  font-family: PingFangSC;
  color: #999999;
}

.text_4 {
  margin-bottom: 0.2rem;
}
.group_6 {
  padding: 0.3rem 1rem 0.3rem 1rem;
}
.font_3 {
  font-size: 0.22rem;
  font-family: PingFangSC;
  line-height: 0.4rem;
  color: #000000;
}
.text_5 {
  font-size: 0.24rem;
  line-height: 0.22rem;
  margin-top: 0.12rem;
  margin-left: 0.2rem;
}
.text_company {
  margin-top: 0.12rem;
  margin-left: 0.2rem;
  display: block;
  line-height: 0.22rem;
}
.image_6 {
  width: 0.48rem;
  height: 0.48rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 0.3rem;
}
.pos_3 {
  position: absolute;
  left: 1.22rem;
  bottom: 0.32rem;
}
.section_5 {
  background-image: linear-gradient(180deg, #f9f9f900 0%, #f5f8ff 100%);
  height: 4.13rem;
}
.section_7 {
  background-color: #f5f8ff;
  height: 3rem;
}
.pos_6 {
  position: absolute;
  left: 0;
  right: 0;
  top: 9.86rem;
}
.section_4 {
  padding: 0.26rem 0.12rem 0rem 0.2rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
  font-size: 0px;
  position: relative;
}
.space-y-24 > *:not(:first-child) {
  margin-top: 0.48rem;
}
.pos_4 {
  position: fixed;
  left: 0.3rem;
  right: 0.3rem;
  top: 4.3rem;
  bottom: 1.4rem;
  overflow-y: hidden;
}
.group_7 {
  margin-left: 0.04rem;
}
.text_6 {
  color: #ff7a1a;
  text-align: justify;
}
.text_7 {
  text-align: justify;
}
.text_8 {
  color: #2a7eff;
  text-align: justify;
  text-decoration: underline;
}
.text_9 {
  text-align: justify;
}
.text_10 {
  color: #437eff;
  text-align: justify;
}
.space-y-8 > *:not(:first-child) {
  margin-top: 0.16rem;
}
.group_8 {
  margin-right: 0.2rem;
  width: 6.5rem;
  height: 41vh;
  overflow: scroll;
}
.font_4 {
  font-size: 0.26rem;
  font-family: PingFangSC;
  line-height: 0.4rem;
  color: #000000;
}
.text_11 {
  text-align: justify;
  text-indent: 0px;
}
.text_12 {
  text-align: justify;
  text-indent: 2.34rem;
}
.pos_5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.section_8 {
  background-color: #ffffff;
  box-shadow: 0px 0.04rem 0.08rem #00000080;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.section_8 {
  background-color: #ffffff;
  box-shadow: 0px 0.04rem 0.08rem #00000080;
  margin-top: 0rem;
  margin-bottom: 0rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
}

.button {
  margin: 0.2rem 0.3rem 0rem;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16701366250750358205.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_13 {
  color: #ffffff;
  font-size: 0.36rem;
  font-family: PingFangSC;
  line-height: 0.36rem;
}
.self-center {
  align-self: center;
}
</style>
